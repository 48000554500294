<style lang="scss" src="./about.component.scss"></style>
<template>
  <div class="about">
    <div class="about_bg"></div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="about-title mt-20 mb-20">
            <h6>{{ local.homeAbout.title }}</h6>
            <!--            <h1>Est gravida odio sagittis dolor amet lectus.</h1>-->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="about-information clearfix mb-35" v-for="(lead,index) of leads" :key="index">
            <img :src="lead.fileUrl" alt="">
            <p>{{ local.projectDetails.ourValue }}</p>
            <h1 class="mt-20 mb-30">{{ lead.title }}</h1>
            <span>{{ lead.description }}</span>
          </div>
        </div>
      </div>
      <div class="row mt-35">
        <div class="col-12">
<!--          <tab-wrapper-component isCustom="about" @avtiveTab="showAtiveTab" class="mt-75" :data="aboutTypesList" :isFlexTabWrapper="true"-->
<!--                                 :isInlineActive="true" :tabWrapperHeaders="aboutTypesTitle">-->
<!--            <template v-slot:item="{item}">-->
<!--              <div>-->
<!--                <div class="about_who">-->
<!--                  <p>{{ item.description }}</p>-->
<!--                </div>-->
<!--                <div class="about_how" v-if="activeTab === 1">-->
<!--                  <div class="about_how-title mb-35">-->
<!--                    <h6>How we work</h6>-->
<!--                    <h1>Ut maecenas adipiscing sed nisi ornare ultrices id eget amet.</h1>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </template>-->
<!--          </tab-wrapper-component>-->
<!--          <financial-reports-component :items="financialReports"/>-->
          <tab-wrapper-component isCustom="about" class="mt-40" :data="workSkills" :isFlexTabWrapper="false"
                                 :isInlineActive="false" :tabWrapperHeaders="workSkillsHeader">
            <template v-slot:item="{item}">
              <div class="about_how-content">
                {{ item.title }}
              </div>
            </template>
          </tab-wrapper-component>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="js" src="./about.component.js"></script>
