import TabWrapperComponent from "@/core/shared/tab/tab-wrapper.component.vue";
import FinancialReportsComponent from "./components/financial-reports.component.vue";
import {mapGetters} from "vuex";

export default {
    name: 'about-component',
    components:{
        'tab-wrapper-component': TabWrapperComponent,
        'financial-reports-component': FinancialReportsComponent
    },
    data(){
        return{
            activeTab: 1
        }
    },
    computed: {
        ...mapGetters({
            local: '_getLocal',
            leads: 'about/_getLeads',
            aboutTypesTitle: 'about/_getAboutTypesTitle',
            aboutTypesList: 'about/_getAboutTypesList',
            workSkillsHeader: 'about/_getWorkSkillsHeader',
            workSkills: 'about/_getWorkSkills',
            financialReports: 'about/_getFinancialReports'
        }),
    },
    methods:{
        showAtiveTab(item){
            this.activeTab = item
        }
    },
    created() {
        this.$store.dispatch("about/getLeads")
        this.$store.dispatch('about/getAboutType')
        this.$store.dispatch('about/getWorkSkills')
        this.$store.dispatch('about/getFinancialReports')
    }
}
